import { useState, Suspense } from "react";
import MobileNav from "../MobileNav";
import { Spinner } from "react-bootstrap";
import Navigation from "../Navigation";
import TopNav from "../TopNav";
import { Outlet } from "react-router-dom";
import { UIProvider } from "../../context/UIProvider";
import { UserProvider } from "../../context/UserProvider";
import styles from "./BodyLayout.module.css";

const BodyLayout = () => {
  const [mobileNavIsExpanding, setMobileNavIsExpanding] = useState(false);

  const toggleMobileNav = () => {
    setMobileNavIsExpanding((prev) => !prev);
  };

  let spinnerBlock = (
    <div className={styles.center}>
      <Spinner animation="border" />
    </div>
  );

  return (
    <>
      <UserProvider>
        <UIProvider>
          {mobileNavIsExpanding && (
            <MobileNav
              toggle={mobileNavIsExpanding}
              toggleHandler={toggleMobileNav}
            />
          )}
          <div className="main-nav">
            <Navigation />
          </div>
          <div className={styles["body-container"]}>
            <TopNav onClick={toggleMobileNav} />
            <Suspense fallback={spinnerBlock}>
              <Outlet />
            </Suspense>
          </div>
        </UIProvider>
      </UserProvider>
    </>
  );
};

export default BodyLayout;
