import { useMemo, useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { jwtDecode } from "jwt-decode";
import useUserContext from "../hooks/useUserContext";
import useAxiosAvatar from "../hooks/useAxiosAvatar";

const RequiredAuth = ({ allowedRoles }) => {
  const { auth } = useAuth();
  const { setUserInfo } = useUserContext();
  const getAvatar = useAxiosAvatar();

  const user = useMemo(() => {
    const { accessToken } = auth;

    const decoded = accessToken ? jwtDecode(accessToken) : undefined;

    const user = decoded?.UserInfo;

    return user;
  }, [auth]);

  const roles = user?.roles ? user.roles : [];

  useEffect(() => {
    const runGetAvatar = async (avatar, userId) => {
      await getAvatar(avatar, userId);
    };
    if (user) {
      const { email, name, avatar, settings, userId, roles } = user;

      setUserInfo((prev) => {
        console.log("setting user");
        return {
          ...prev,
          email,
          userName: name,
          avatar,
          currencySetting: settings,
          userId,
          roles,
        };
      });

      runGetAvatar(avatar, userId);
    }
  }, [setUserInfo, user]);

  const returnedComp = roles
    ?.map((role) => allowedRoles.includes(role))
    .find((val) => val === true) ? (
    <Outlet />
  ) : auth?.accessToken ? (
    <Navigate to="/unauthorized" replace />
  ) : (
    <Navigate to="/login" replace />
  );

  return <>{returnedComp}</>;
};

export default RequiredAuth;
