import classes from "./NotP.module.css";

const NotP = (props) => {
  return (
    <div className={classes.wrapper}>
      {props.not !== null && (
        <p className={props.not.code === "ok" ? classes.success : classes.err}>
          {props.not.text}
        </p>
      )}
    </div>
  );
};

export default NotP;
