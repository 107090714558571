import { useState, useRef, useEffect } from "react";
import classes from "./Navigation.module.css";
import { NavLink } from "react-router-dom";
import {
  BsArrowRightSquareFill,
  BsFillArrowLeftSquareFill,
} from "react-icons/bs";
import { navList } from "./navList";
import useUIContext from "../hooks/useUIContext";

const Navigation = () => {
  const [isExpanding, setIsExpanding] = useState(true);

  const navRef = useRef();

  // const uiContext = useContext(UIContext);
  const { setSideBarSize } = useUIContext();

  useEffect(() => {
    const el = navRef.current;
    const size = el.getBoundingClientRect();
    setSideBarSize(size);
  }, [isExpanding, setSideBarSize]);

  let navClass;
  if (isExpanding) {
    navClass = `${classes.nav} ${classes.expanding}`;
  } else {
    navClass = `${classes.nav} ${classes.min}`;
  }

  return (
    <div className={navClass} ref={navRef}>
      <div className={classes["toggle-button"]}>
        {isExpanding && (
          <button onClick={() => setIsExpanding(false)}>
            <BsFillArrowLeftSquareFill />
          </button>
        )}
        {!isExpanding && (
          <button onClick={() => setIsExpanding(true)}>
            {" "}
            <BsArrowRightSquareFill />
          </button>
        )}
      </div>

      <div className={classes.logo}>{isExpanding && <p>Your logo</p>}</div>

      {navList.length > 0 &&
        navList.map((item, index) => (
          <div key={index}>
            <NavLink to={item.path}>
              {item.icon}
              {isExpanding && <span>{item.content}</span>}
            </NavLink>
          </div>
        ))}
    </div>
  );
};

export default Navigation;
