import { createContext, useState } from "react";
import defaultAva from "../assets/img/default.jpg";
import navAva from "../assets/img/defaultAva.jpeg";

const UserContext = createContext({
  userInfo: {
    email: null,
    userName: null,
    avatar: null,
    currencySetting: null,
    userId: null,
    roles: null,
  },
  setUserInfo: () => {},
  avatarURL: null,
  setAvatarURL: () => {},
});

export const UserProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState({
    email: null,
    userName: null,
    avatar: null,
    currencySetting: {
      seperator: ",",
      decimal: 0,
    },
    userId: null,
    roles: [],
  });
  const [avatarURL, setAvatarURL] = useState({
    profile: defaultAva,
    nav: navAva,
  });

  return (
    <UserContext.Provider
      value={{
        userInfo,
        setUserInfo,
        avatarURL,
        setAvatarURL,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
