import classes from "./Loader.module.css";
import Spinner from "react-bootstrap/esm/Spinner";

const Loader = (props) => {
  return (
    <div className={classes.wrapper}>
      {props.isLoading && <Spinner variant="success" animation="border" />}
    </div>
  );
};

export default Loader;
