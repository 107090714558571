import { NavLink } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const Test = () => {
  const axiosPrivate = useAxiosPrivate();

  const onGet = async () => {
    try {
      const response = await axiosPrivate.get("/api/test");
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };

  const onPost = async () => {
    try {
      const response = await axiosPrivate.post("/api/test");
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };

  const onGetToken = async () => {
    try {
      const response = await axiosPrivate.get("/refresh");
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <h1>Test</h1>
      <button onClick={onGet}>Get test</button>
      <button onClick={onPost}>Post test</button>
      <button onClick={onGetToken}>Get Token</button>

      <NavLink to="/">Home</NavLink>
    </div>
  );
};

export default Test;
