import { useState, useRef, useEffect } from "react";
import classes from "./Profile.module.css";
import Loader from "../share/notificationUI/Loader";
import useUserContext from "../hooks/useUserContext";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const Profile = () => {
  const { userInfo, setUserInfo } = useUserContext();
  const { userName, userId, email } = userInfo;

  const [editIsOn, setEditOn] = useState(false);
  const [changeModeOn, setChangeModeOn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [not, setNot] = useState(null);
  const [selectedFile, setSelectedFile] = useState();
  const { avatarURL, setAvatarURL } = useUserContext();

  const [avatar, setAvatar] = useState(avatarURL.profile);

  const axiosPrivate = useAxiosPrivate();

  const updateName = (name) => {
    setUserInfo((prev) => {
      return {
        ...prev,
        name,
      };
    });
  };

  const [nameInput, setNameInput] = useState("");

  const nameRef = useRef();
  const passFormRef = useRef();
  const uploaderRef = useRef();

  useEffect(() => {
    setNameInput(userName);
  }, [userName]);

  useEffect(() => {
    avatarURL?.profile && setAvatar(avatarURL.profile);
  }, [avatarURL]);

  let inputClass = null;
  let inputRead = false;
  if (!editIsOn) {
    inputClass = classes.disable;
    inputRead = true;
  }
  if (editIsOn) {
    inputRead = false;
    inputClass = null;
  }

  let passFormClass;
  passFormClass = !changeModeOn ? classes.hidden : null;

  const nameChangeHandler = (e) => {
    setNameInput(e.target.value);
  };

  const undoEdit = () => {
    setEditOn(false);
    setNameInput(userName);
    nameRef.current.inputvalue = userName;
  };

  const nameSubmit = async (e) => {
    e.preventDefault();

    const fetchURL = `${process.env.REACT_APP_BACKEND_URL}api/auth/user/edit-user`;
    const submitBody = JSON.stringify({
      name: nameInput,
    });
    try {
      const res = await axiosPrivate.patch(fetchURL, submitBody);
      console.log(res);
      if (res?.status === 200) {
        const data = res?.data;
        // setNameInput(data?.new_name);
        updateName(data?.new_name);
      }
    } catch (err) {
      console.log(err);
      nameRef.current.value = userName;
      setNot(err?.response?.data);
      setTimeout(() => setNot(), [3000]);
    } finally {
      setEditOn(false);
    }
  };

  const passSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const passInput = {
      cPass: null,
      nPass: null,
      rePass: null,
    };
    for (let i = 0; i < e.target.length; i++) {
      const id = e.target[i].id;
      if (id.length > 0) {
        passInput[`${id}`] = e.target[i].value;
      }
    }

    const fetchURl = `${process.env.REACT_APP_BACKEND_URL}api/auth/user/change-pass`;

    const submitBody = JSON.stringify(passInput);
    try {
      const res = await axiosPrivate.post(fetchURl, submitBody);
      console.log(res);

      if (res?.status === 200) {
        setNot(res?.data?.data?.message);
        e.target.reset();
      }
    } catch (err) {
      console.log(err);
      setNot(err?.response?.data);
      setTimeout(() => {
        setNot();
      }, 3000);
    } finally {
      setIsLoading(false);
    }
  };

  const fileSelectHandler = (e) => {
    const file = e.target.files[0];
    console.log(e.target.files[0]);
    setSelectedFile(e.target.files[0]);

    const reader = new FileReader();

    reader.onloadend = () => {
      console.log(reader.result);
      setAvatar(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const onUploadHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (selectedFile) {
      const data = new FormData();
      data.append("avatar", selectedFile, selectedFile.name);
      data.append("userId", userId);
      const fetchUrl = `${process.env.REACT_APP_BACKEND_URL}api/auth/user/set-avatar`;

      try {
        const res = await axiosPrivate.post(fetchUrl, data, {
          headers: {
            "Content-Type": "mulitpart/form-data",
          },
        });

        const resData = res?.data;

        setAvatarURL({
          profile: resData.data.urlProfile,
          nav: resData.data.urlNav,
        });
      } catch (err) {
        console.log(err);
        setNot(err?.response?.data);
        setTimeout(() => setNot(), [3000]);
      } finally {
        setIsLoading(false);
      }
    } else {
      setNot("Please upload your new avatar image first.");
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div>
          <div className={classes.avatar}>
            <img src={avatar} alt="Your avatar" />
          </div>
          <div>
            <form onSubmit={onUploadHandler} className={classes["ava-upload"]}>
              <input
                style={{ display: "none" }}
                type="file"
                onChange={fileSelectHandler}
                ref={uploaderRef}
                name="avatar"
                accept=".png, .jpg, .jpg, .webp"
              />
              <button type="button" onClick={() => uploaderRef.current.click()}>
                Upload your profile image
              </button>
              <button type="submit">Update</button>
            </form>
          </div>
        </div>
        <div>
          <form onSubmit={nameSubmit} ref={passFormRef}>
            <div className={classes["input-wrapper"]}>
              <label className={classes.label}>Name:</label>
              <input
                id="nameInput"
                defaultValue={nameInput}
                className={inputClass}
                readOnly={inputRead}
                onChange={nameChangeHandler}
                ref={nameRef}
              />
            </div>
            <div>
              <p>
                <span className={classes.label}>Email: </span>
                {email}
              </p>
            </div>
            <div className={classes["btn-panel"]}>
              <button
                type="button"
                onClick={() => setEditOn(true)}
                className={editIsOn ? classes.disable : null}
              >
                Edit
              </button>
              <button
                type="button"
                className={!editIsOn ? classes.disable : null}
                onClick={undoEdit}
              >
                Undo
              </button>
              <button
                type="submit"
                className={!editIsOn ? classes.disable : null}
              >
                Save
              </button>
            </div>
          </form>
        </div>
        <div className={classes["pass-form"]}>
          <button
            className={classes["primary-btn"]}
            type="button"
            onClick={() => setChangeModeOn((prev) => !prev)}
          >
            Change your password
          </button>
          <form className={passFormClass} onSubmit={passSubmit}>
            <div>
              <label htmlFor="cPass">Enter your current password</label>
              <input id="cPass" type="password" />
            </div>
            <div>
              <label htmlFor="nPass">Enter your new password</label>
              <input id="nPass" type="password" />
            </div>
            <div>
              <label htmlFor="rePass">Enter your current password</label>
              <input id="rePass" type="password" />
            </div>
            <div className={classes["btn-panel"]}>
              <Loader isLoading={isLoading} />
              <button type="submit" disabled={isLoading ? true : false}>
                Save
              </button>
            </div>
          </form>
        </div>
        <div className={classes.notDiv}>
          {not && <p className={classes.not}>{not}</p>}
        </div>
      </div>
    </div>
  );
};

export default Profile;
