import { useEffect } from "react";
import { axiosPrivate } from "../api/axios";
import useAuth from "./useAuth";
import useRefresh from "./useRefresh";

const useAxiosPrivate = () => {
  const { auth } = useAuth();
  const refresh = useRefresh();

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        if (!config?.headers?.authorization) {
          config.headers.authorization = `Bearer ${auth?.accessToken}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (res) => res,
      async (err) => {
        const prev = err?.config;
        if (!prev.sent && err?.response?.status === 403) {
          prev.sent = true;
          const accessToken = await refresh();
          prev.headers.authorization = `Bearer ${accessToken}`;
          return axiosPrivate(prev);
        }
        return Promise.reject(err);
      }
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    };
  });

  return axiosPrivate;
};

export default useAxiosPrivate;
