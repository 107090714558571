import { useState, useEffect } from "react";
import classes from "./TopNav.module.css";
import { CgMenuRound } from "react-icons/cg";
import { NavLink } from "react-router-dom";
import logo from "../assets/img/logo150.png";
import useLogout from "../hooks/useLogout";
import useUserContext from "../hooks/useUserContext";

const TopNav = (props) => {
  const [isExpanding, setIsExpanding] = useState(false);
  const { userInfo, avatarURL } = useUserContext();
  const auth = userInfo; // auth here doesn't have correct meaning, be here temporarily to prevent app breakout

  console.log(auth);

  const [avatarName, setAvatarName] = useState("");
  const [avatarImg, setAvatarImg] = useState(avatarURL.nav);

  const userName = auth.userName;

  const logout = useLogout();

  useEffect(() => {
    const displayName =
      userName && userName.length > 6 ? userName.slice(0, 6) : userName;
    setAvatarName(displayName);
  }, [userName]);

  useEffect(() => {
    console.log(avatarURL);
    avatarURL?.nav && setAvatarImg(avatarURL?.nav);
  }, [avatarURL]);

  const onLogout = async () => {
    await logout();
  };

  return (
    <div className={classes["top-nav"]}>
      <div className={classes["menu-toggle"]}>
        <button onClick={props.onClick}>
          <CgMenuRound />
        </button>
      </div>
      <div className={classes.logo}>
        <NavLink to="/">
          <img src={logo} alt="Min wallet logo" />
        </NavLink>
      </div>
      <div
        className={classes.profile}
        onClick={() => setIsExpanding((prev) => !prev)}
      >
        <div>
          <img src={avatarImg} alt="user avatar" />
        </div>
        <div className={classes.name}>{avatarName}</div>
        {isExpanding && (
          <div className={classes["menu-sub"]}>
            <div className={classes.info}>
              <div>{auth.email}</div>
            </div>
            <div className={classes["menu-list"]}>
              <NavLink to="/profile">Profile</NavLink>
            </div>
            <div className={classes.setting}>
              <NavLink to="/settings">Account Settings</NavLink>
              <div onClick={onLogout}>Log out</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TopNav;
