import classes from "./MobileNav.module.css";
import { BsFillArrowLeftSquareFill } from "react-icons/bs";
import { navList } from "./navList";
import { NavLink } from "react-router-dom";

const MobileNav = (props) => {
  let navClass;
  if (!props.toggle) {
    navClass = classes.hidden;
  } else {
    navClass = classes["mobile-nav"];
  }

  return (
    <div className={navClass}>
      <div className={classes.nav}>
        <div className={classes["toggle-button"]}>
          <button onClick={props.toggleHandler}>
            <BsFillArrowLeftSquareFill />
          </button>
        </div>
        {navList &&
          navList.map((item, index) => (
            <div key={index}>
              <NavLink to={item.path}>
                {item.icon}
                <span>{item.content}</span>
              </NavLink>
            </div>
          ))}
      </div>
      <div onClick={props.toggleHandler} className={classes.overlay}></div>
    </div>
  );
};

export default MobileNav;
