import { useState } from "react";
import PageTitle from "../UI/PageTitle";
import Form from "react-bootstrap/form";
import Button from "react-bootstrap/button";
import classes from "./Settings.module.css";
import NotP from "../share/notificationUI/NotP";
import useUserContext from "../hooks/useUserContext";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const Settings = () => {
  const { userInfo, setUserInfo } = useUserContext();
  const { currencySetting } = userInfo;
  const axiosPrivate = useAxiosPrivate();

  const updateCurrency = (settings) => {
    setUserInfo((prev) => {
      return {
        ...prev,
        currencySetting: settings,
      };
    });
  };

  const [seperator, setSeperator] = useState(currencySetting?.seperator);
  const [decimal, setDecimal] = useState(currencySetting?.decimal);
  const [not, setNot] = useState({});

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (seperator && decimal) {
      const newSettings = {
        seperator,
        decimal,
      };
      const submitData = JSON.stringify(newSettings);

      const httpUrl = `${process.env.REACT_APP_BACKEND_URL}api/auth/user/set-currency`;
      try {
        const res = await axiosPrivate.patch(httpUrl, submitData);

        console.log(res);

        if (res?.status === 200) {
          updateCurrency({ seperator, decimal });
          setNot({
            code: "ok",
            text: res?.statusText,
          });
        }
      } catch (err) {
        setNot({
          code: "err",
          text: err?.response?.statusText,
        });
      } finally {
        setTimeout(() => {
          setNot({});
        }, 3500);
      }
    } else {
      setNot({
        code: "err",
        text: "You didn't make any changes!",
      });
    }
  };

  const seperatorOnChange = (e) => {
    setSeperator(e.target.value);
  };

  const decimalOnChange = (e) => {
    setDecimal(e.target.value);
  };

  return (
    <div>
      <PageTitle title="Settings" />
      <div className={classes.wrapper}>
        <Form onSubmit={onSubmitHandler}>
          <Form.Group>
            <Form.Label>Select your thousand seperator</Form.Label>
            <Form.Select
              onChange={seperatorOnChange}
              value={currencySetting.seperator}
            >
              <option value=",">,</option>
              <option value=".">.</option>
            </Form.Select>
          </Form.Group>
          <Form.Group>
            <Form.Label>Select allowed number of decimal places</Form.Label>
            <Form.Select
              onChange={decimalOnChange}
              value={currencySetting.decimal}
            >
              <option value="0">none</option>
              <option value="2">2</option>
            </Form.Select>
          </Form.Group>
          <NotP not={not} />
          <div>
            <Button variant="primary" size="sm" type="submit">
              Save
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Settings;
